import React from "react";

const SingleProcessBlock = ({ list, width }) => {
  return (
    <>
      {list.map((item) => (
        <div
          className={`relative mb-12 p-5 rounded-2.5xl border border-jacarta-100 bg-white transition-shadow hover:shadow-xl dark:border-jacarta-50 dark:bg-jacarta-700 ${width}`}
          key={item.id}
        >
         
          <h3 className="block font-display text-xl font-medium group-hover:text-accent dark:text-white text-center">
            {item.title}
          </h3>
          {/* <p className="dark:text-jacarta-300">{item.text}</p> */}
        </div>
      ))}
    </>
  );
};

export default SingleProcessBlock;
