// import { useState, useEffect } from "react";

// const Typewriter = () => {
//   const words = ["Brands", "Influencers", "Creators", "Supporters"];
//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setIndex((prevIndex) => (prevIndex + 1) % words.length);
//     }, 8000 / words.length);

//     return () => clearInterval(intervalId);
//   }, [words.length]); // Include words.length in the dependency array

//   return (
//     <h2 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white text-center">
//       {words.map((word, i) => (
//         <span
//           key={i}
//           className={`${
//             i === index ? "opacity-100" : "opacity-0"
//           } transition-opacity duration-1000`}
//         >
//           {word}
//         </span>
//       ))}
//     </h2>
//   );
// };

// export default Typewriter;
import Typewriter from "typewriter-effect";
function TypingText() {
  return (
    <div className="flex justify-center">
      <h2 className="font-body text-4 dark:text-white  lg:text-5xl xl:text-6xl text-center italic">
        For&nbsp; 
      </h2>

      <h2 className="font-body text-4xl text-jacarta-700 lg:text-5xl xl:text-6xl text-center italic animate-gradient">
        <Typewriter
          options={{
            strings: [
              "Causes",
              "Brands",
              "Influencers",
              "Creators",
              "Innovators",
              "Educators",
              "Supporters",
              "The Next Generation of Impacteers",
            ],
            autoStart: true,
            loop: true,
          }}
        />
      </h2>
      <h2 className="font-body text-4 dark:text-white  lg:text-5xl xl:text-6xl text-center italic">
      
      </h2>
    </div>
  );
}

export default TypingText;
